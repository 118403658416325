import React, {useRef, useEffect} from 'react';
import './App.css';
import l5Logo from './bucking-bulls.JPG'
import {FaChevronDown} from 'react-icons/fa'
import ReactGA from 'react-ga';

const App = () => {
  const scrollRef = useRef(null);
  
  function initializeReactGA() {
    ReactGA.initialize('UA-156956140-7');
    ReactGA.pageview('/');
}

  useEffect(()=>{
    initializeReactGA();
    console.log("Google Analytics set up");
  },[])
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={l5Logo} className="App-logo" alt="logo" />
        <p className="app-name">
          L5 Bucking Bulls
        </p>
        <p className="app-sub">
          Here to catch the stream?
        </p>
        <div className="chevron" onClick={()=>{
          window.scrollTo({behavior: 'smooth', 
                           top: scrollRef.current.offsetTop});
        }}>
        <FaChevronDown 
          color={'orange'}
          size={45}
        />
        </div>
      </header>
      <div className="smEmbed" style={{padding: '0 0 56.25% 0', position: 'relative', height: 0, overflow: 'hidden'}}>
        <iframe 
          src="//player.streammonkey.com/5df12b51e0968" 
          width="100%" 
          height="100%" 
          frameborder="0" 
          style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}} 
          allow="autoplay" 
          allowfullscreen 
          webkitAllowFullScreen 
          mozAllowFullscreen>
        </iframe>
        </div>
        <div className="scrollToBot" ref={scrollRef}></div>
    </div>
  );
}

export default App;
